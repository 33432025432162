import ClientService from "@/services/client.service";
export default {
    data() {
      return { /* data */ }
    },
    created: function () { },
    methods: {
        mixin_setLoginData(is_login = false, param = []){
          if(is_login){
            localStorage.setItem("user", JSON.stringify(param));
            this.$store.dispatch("keepSignedIn/keepSign", true);
            this.mixin_UpdateTokenAndTime(param.data.token);
            this.mixin_CheckVisitActivityAndTokenStatus();
          }else{
            //Clearing SetTimeOut Data --------
            let intervalVisitingActivityObj = localStorage.getItem("visitActvityProcess");
            if(intervalVisitingActivityObj){
              clearInterval(intervalVisitingActivityObj);
            }

            let intervalNotificationActivityObj = localStorage.getItem("notificationActvityProcess");
            if(intervalNotificationActivityObj){
              clearInterval(intervalNotificationActivityObj);
            }
            //End -----------

            this.$store.dispatch("keepSignedIn/keepSign", false);
            this.$store.dispatch("auth/logout"); //Clear Local Storage Here
            this.$router.push({ path: "/signin" });
          }
        },

        mixin_CheckVisitActivityAndTokenStatus(){
          var rootObject = this;
          var intervalObj = null;
          var maxIdleTime = process.env.VUE_APP_MAXIDLETIME; 
          var intervalTime = process.env.VUE_APP_CHECKACTIVITYINTERVALTIME;
          var tokenRefreshTime = process.env.VUE_APP_TOKENREFRESHTIME; 
          intervalObj = setInterval(function(){  
            var current = new Date();
            var lasttokenUpdateTime = localStorage.getItem('tkutim');
            var lastActivityTime = localStorage.getItem('lastActivityTime');
            var totalIdleTime = parseInt(lastActivityTime)+parseInt(maxIdleTime);
            if ( totalIdleTime < current.getTime()) {
              rootObject.mixin_setLoginData(false);
            }else{
              var nextTokenRefreshTime = parseInt(lasttokenUpdateTime)+parseInt(tokenRefreshTime);
              if( current.getTime() >= nextTokenRefreshTime){
                rootObject.mixin_getRefreshToken();
              }
            }
          }, intervalTime);
          localStorage.setItem('visitActvityProcess', intervalObj);
        },
    
        mixin_SetLastActivityTime(){
          var current = new Date();
          localStorage.setItem('lastActivityTime', current.getTime());
        },
    
        mixin_UpdateTokenAndTime(token){
          var current = new Date();
          localStorage.removeItem("tkutim");
          localStorage.setItem("tkutim", current.getTime());
          //Update User Token
          let user = JSON.parse(localStorage.getItem("user"));
          user.data.token = token;
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(user));
          //console.log("token updated");
        },
    
        mixin_getRefreshToken(){
          var rootObject = this;
          ClientService.getApiRefreshToken().then(
            (response) => {
              if (response.data.data) {
                rootObject.mixin_UpdateTokenAndTime(response.data.data.token);
              }else{
                rootObject.mixin_setLoginData(false);
              }
            },
            (error) => {
              this.content =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
                rootObject.mixin_setLoginData(false);
            }
          );

        },
    },
   }