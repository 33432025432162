const USE_API_MIDDLEWARE =parseInt(process.env.VUE_APP_USE_API_MIDDLEWARE);
export const getMiddlewareFile = (api_endpoint = false, api_endpoint_prefix = "layer_common") => {
  if(USE_API_MIDDLEWARE == 1){
    return "get.php";
  }
  return api_endpoint_prefix + '/' + api_endpoint;
};
export const postMiddlewareFile = (api_endpoint = false, api_endpoint_prefix = "layer_common") => {
  if(USE_API_MIDDLEWARE == 1){
    return "post.php";
  }
  return api_endpoint_prefix + '/' + api_endpoint;
};
export const updateMiddlewareFile = (api_endpoint = false, api_endpoint_prefix = "layer_common") => {
  if(USE_API_MIDDLEWARE == 1){
    return "update.php";
  }
  return api_endpoint_prefix + '/' + api_endpoint;
};
export const deleteMiddlewareFile = (api_endpoint = false, api_endpoint_prefix = "layer_common") => {
  if(USE_API_MIDDLEWARE == 1){
    return "delete.php";
  }
  return api_endpoint_prefix + '/' + api_endpoint;
};