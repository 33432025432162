import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";

const routes = [
  {
    name: "guest",
    path: "/signin",
    component: () => import("../components/layouts/Guest.vue"),
    meta: {
      middleware: "guest",
    },
    children: [
      {
        path: "",
        name: "signin",
        component:()=> import(/*webpackChunck: "signIn" */ '../pages/Auth/SignInview.vue'),
        meta: {
          title: `Sign In`,
        },
      },
      {
        path: "/sign-up",
        name: "signup",
        component:()=> import(/*webpackChunck: "signIn" */ '../pages/Auth/SignUpview.vue'),
        meta: {
          title: `Sign Up`,
        },
      },
      {
        path: "/otp-verification",
        name: "otpverification",
        beforeEnter: (to,from, next) => {
          if (from.name) {
            next()
          } else {
            next('signin')
          }
        },
        component:()=> import(/*webpackChunck: "signIn" */ '../pages/Auth/OtpVerify.vue'),
        meta: {
          title: `Sign Up`,
        },
      },
      {
        path: "/get-company-info",
        name: "getcompanyinfo",
        beforeEnter: (to,from, next) => {
          if (from.name) {
            next()
          } else {
            next('signin')
          }
        },
        component:()=> import(/*webpackChunck: "signIn" */ '../pages/Auth/GetCompanyInfo.vue'),
        meta: {
          title: `Sign Up`,
        },
      },
      {
        path: "/sign-up-success",
        name: "signupsuccess",
        beforeEnter: (to,from, next) => {
          if (from.name) {
            next()
          } else {
            next('signin')
          }
        },
        component:()=> import(/*webpackChunck: "signIn" */ '../pages/Auth/SignUpSuccess.vue'),
        meta: {
          title: `Sign Up`,
        },
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        component:()=> import(/*webpackChunck: "home" */ '../pages/Auth/ForgotPassword.vue'),
        meta: {
          title: `Forgot Password`,
        },
      },
      {
        path: "/forgot-password-otp-verify",
        name: "forgotPasswordotpverify",
        component:()=> import(/*webpackChunck: "home" */ '../pages/Auth/ForgotPasswordOtpVerify.vue'),
        meta: {
          title: `Forgot Password`,
        },
      },

    ],
  },
  {
    name: "base",
    path: "/",
    component: () => import("../components/layouts/Default.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ '../components/profile/UpdateProfile.vue'),
        meta: {
          title: `Profile`,
        },
      },
      {
        path: "change-password",
        name: "changePassword",
        component: () => import(/* webpackChunkName: "profile" */ '../components/profile/ChangePassword.vue'),
        meta: {
          title: `Change Password`,
        },
      },
      {
        path: "home",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ '../pages/HomeView.vue'),
        meta: {
          title: `Home`,
        },
      },
      {
        path: "price",
        name: "price",
        component: () => import(/* webpackChunkName: "price" */ '../components/home/PriceView.vue'),
        meta: {
          title: `price`,
        },
      },
      {
        path: "checkout",
        name: "checkout",
        component: () => import(/* webpackChunkName: "home" */ '../components/home/CheckoutView.vue'),
        meta: {
          title: `Checkout`,
        },
      },
      {
        path: "payment-success",
        name: "PaymentSuccess",
        component: () => import(/* webpackChunkName: "home" */ '../components/home/PaymentSuccess.vue'),
        meta: {
          title: `Checkout`,
        },
      },

      {
        path: "payment-failed",
        name: "PaymentFailed",
        component: () => import(/* webpackChunkName: "home" */ '../components/home/PaymentFailed.vue'),
        meta: {
          title: `Checkout`,
        },
      },
      
      {
        path:'/company',
        name:'company',
        component:()=> import(/*webpackChunck: "subscriber" */ '../pages/CompanyView.vue'),
        meta: {
          title: `My Company`,
        },
      },
      {
        path:'/team',
        name:'team',
        component:()=> import(/*webpackChunck: "subscriber" */ '../components/company/CompanyTeam.vue'),
        meta: {
          title: `Company Team`,
        },
      },
      {
        path:'/activity',
        name:'activity',
        component:()=> import(/*webpackChunck: "subscriber" */ '../components/company/CompanyActivity.vue'),
        meta: {
          title: `Company Activity`,
        },
      },
      
      {
        path:'/network',
        name:'network',
        component:()=> import(/*webpackChunck: "network" */ '../pages/NetworkView.vue'),
        meta: {
          title: `Network List`,
        },
      },
      {
        path:'/server',
        name:'server',
        component:()=> import(/*webpackChunck: "network" */ '../components/network/NetworkServer.vue'),
        meta: {
          title: `Server`,
        },
      },
      {
        path:'/subscription',
        name:'subscription',
        component:()=> import(/*webpackChunck: "subscription" */ '../pages/SubscriptionView.vue'),
        meta: {
          title: `Subscription List`,
        },
      },
      {
        path:'/payment-history',
        name:'paymentHistory',
        component:()=> import(/*webpackChunck: "subscription" */ '../components/subscription/SubscriptionPaymentHistory.vue'),
        meta: {
          title: `Payment History`,
        },
      },
      {
        path:'/payment-method',
        name:'paymentMethod',
        component:()=> import(/*webpackChunck: "subscription" */ '../components/subscription/SubscriptionPaymentMethod.vue'),
        meta: {
          title: `Payment Method`,
        },
      },
      {
        path:'/whitelist-ip',
        name:'whitelistip',
        component:()=> import(/*webpackChunck: "whitelistip" */ '../pages/WhitelistipView.vue'),
        meta: {
          title: `Whitelist IP`,
        },
      },
      {
        path:'/settings',
        name:'settings',
        component:()=> import(/*webpackChunck: "settings" */ '../pages/SettingsView.vue'),
        meta: {
          title: `Settings`,
        },
      },
      {
        path:'/settings-configuration',
        name:'settingsConfig',
        component:()=> import(/*webpackChunck: "settings" */ '../components/settings/SettingConfiguration.vue'),
        meta: {
          title: `Settings Configuration`,
        },
      },
      {
        path:'/download',
        name:'download',
        component:()=> import(/*webpackChunck: "download" */ '../pages/DownloadView.vue'),
        meta: {
          title: `Download`,
        },
      },
      {
        path:'/support',
        name:'support',
        component:()=> import(/*webpackChunck: "support" */ '../pages/SupportView.vue'),
        meta: {
          title: `Support`,
        },
      },
      {
        path:'/faq',
        name:'faq',
        component:()=> import(/*webpackChunck: "support" */ '../components/support/SupportFaq.vue'),
        meta: {
          title: `Faq`,
        },
      },


    ],
  },
]

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  let keepSignedIn = store.state.keepSignedIn.keep_signed_in;
  let auth = store.state.auth.status.loggedIn;
  if (to.meta.middleware === "guest") {
    if (auth) {
      next({ name: "home" });
    }
    next();
  }
  else {
    //If State has loggedin
    if (auth) {
      //check localstorage user data
      let user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.data.token) {
        keepSignedIn = false;
      }
      
      if (!keepSignedIn) {
        if (!keepSignedIn && auth) {
          store.dispatch("auth/logout");
          next({ name: "signin", query: { from: to.path } });
        }
      }
      if(to.name == undefined || to.name == 'base'){
        next({ name: "home" });
      }
      next();
    } else {
      next({ name: "signin", query: { from: to.path } });
    }
  }
});



export default router
