import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));
const userVerifyData = JSON.parse(localStorage.getItem("userVerifyData"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

  initialState["userVerifyData"] = userVerifyData ? userVerifyData : null;

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    //Layer
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          if(user.status_code == 200){
            commit("loginSuccess", user);
          }else{
            commit("loginFailure");
          }
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    //Layer
    logout({ commit }) {
      //AuthService.logout();
      commit("logout");
    },
    
    register({ commit }, user) {
      return AuthService.register(user).then(
        (user) => {
          commit("registerSuccess");
          return Promise.resolve(user);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    //layer
    storeUserVerifyData({ commit }, user) {
      commit("setUserVerifyData", user);
    },
  },
  mutations: {

    //Layer
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },

    //Layer
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    //Layer
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("tkutim");
      localStorage.removeItem("userVerifyData");
      localStorage.removeItem("visitActvityProcess");
      localStorage.removeItem("notificationActvityProcess");
    },

    //Layer
    setUserVerifyData(state, user) {
      localStorage.setItem("userVerifyData", JSON.stringify(user));
      state.userVerifyData = user;
    },

    
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
