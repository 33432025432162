export default function authHeader(api_endpoint = false, api_endpoint_prefix = "layer_common") {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.data.token) {
    return { 
      Authorization: "Bearer " + user.data.token, 
      'Content-Type': 'application/x-www-form-urlencoded',
      'endpoint': api_endpoint,
      'reference': api_endpoint_prefix
    };
  } else {
    return { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'endpoint': api_endpoint,
      'reference': api_endpoint_prefix
    };
  }
}
