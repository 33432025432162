export const getHumanReadableDate = (date) => {
  let dateString = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const getLength = (obj) => {
  return Object.keys(obj).length;
};

export const com_DecryptString = (string, key = "") => {
  if (key == "") {
    key = process.env.VUE_APP_ENCRYPTIONKEY;
  }

  let str = atob(string);
  var output = "";
  for (var i = 0; i < str.length; ) {
    for (var j = 0; j < key.length && i < str.length; j++, i++) {
      output += String.fromCharCode(
        str[i].charCodeAt(0) ^ key[j].charCodeAt(0)
      );
    }
  }
  return output;
};

export const com_EncryptString = (string, key = "") => {
  if (key == "") {
    key = process.env.VUE_APP_ENCRYPTIONKEY;
  }

  let str = string.toString();
  var output = "";
  for (var i = 0; i < str.length; ) {
    for (var j = 0; j < key.length && i < str.length; j++, i++) {
      output += String.fromCharCode(
        str[i].charCodeAt(0) ^ key[j].charCodeAt(0)
      );
    }
  }
  return btoa(output);
};

export const  formatDate = (dateString) =>{
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const validateEmail = (email) => {
  let emailErrorMessage = "";
  var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  var specialChars = /[`!#$%^&*()_+\-=\\|,<>?~]/;

  if (!emailPattern.test(email)) {
    emailErrorMessage = "Invalid email address.";
  } else if (specialChars.test(email)) {
    emailErrorMessage = "Email should not contain special characters.";
  } else {
    emailErrorMessage = "";
  }
  return emailErrorMessage;
};

export const formatAMPM = (date) =>{
  let dateObject = new Date(date);
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  return dateObject.toLocaleTimeString(undefined, options);
};
