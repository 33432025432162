import axiosInstance from "@/plugins/HTTP";
import authHeader from "./auth-header";
import { postMiddlewareFile,getMiddlewareFile } from "@/utils/apiMiddlewarePaths";

//Pass Same Param for authHeader and MiddlewareFile Method

class AuthService {
  //Layer
  login(user) {
    const form = new FormData();
    form.append("login", user.username);
    form.append("password", user.password);
    return axiosInstance.post(postMiddlewareFile("login"), form, {
      headers: authHeader("login"),
    })
    .then((response) => {
      return response.data;
    });
  }

  register(user) {
     //Without Token
    const form = new FormData();
    form.append("verification_type", process.env.VUE_APP_USER_VERIFICAION_TYPE);
    form.append("username", user.username);
    form.append("email", user.username);
    form.append("password", user.password);
    form.append("password_confirmation", user.password_confirmation);
    return axiosInstance.post(postMiddlewareFile("register"), form, {
      headers: authHeader("register"),
    })
    .then((response) => {
      return response.data;
    });
  }

  storeCompanyInfo(companyData) {
     //Without Token
    const form = new FormData();
    form.append("email", companyData.username);
    form.append("company_name", companyData.company_name);
    form.append("city", companyData.city);
    form.append("zipcode", companyData.zipcode);
    form.append("address", companyData.address);
    form.append("country", companyData.country);
    return axiosInstance
    .post(postMiddlewareFile("store-company-info"), form, {
      headers: authHeader("store-company-info"),
    })
    .then((response) => {
      return response.data;
    });
  }

  otpVerification(user) {
     //Without Token
    const form_data = new FormData();
    for (var key in user) {
      form_data.append(key, user[key]);
    }
    return axiosInstance.post(postMiddlewareFile("otp-verification"), form_data, {
      headers: authHeader("otp-verification"),
    })
    .then((response) => {
      return response.data;
    });
  }

  otpResend(user) {
     //Without Token
    const form_data = new FormData();
    for (var key in user) {
      form_data.append(key, user[key]);
    }
    return axiosInstance.post(postMiddlewareFile("resend-otp"), form_data, {
      headers: authHeader("resend-otp"),
    })
    .then((response) => {
      return response.data;
    });
  }
  passwordotpResend(username){
     //Without Token
    const form_data = new FormData();
    for (var key in username) {
      form_data.append(key, username[key]);
    }
    return axiosInstance.post(postMiddlewareFile("password-reset-otp"), form_data, {
      headers: authHeader("password-reset-otp"),
    })
    .then((response) => {
      return response.data;
    });
  }

  ForgotPasswordOtpVerify(user){
     //Without Token
    const form_data = new FormData();
    for (var key in user) {
      form_data.append(key, user[key]);
    }
    return axiosInstance.post(postMiddlewareFile("forgot-password"), form_data, {
      headers: authHeader("forgot-password"),
    })
    .then((response) => {
      return response.data;
    });
  }

  getCountry() {
    //Without Token
    return axiosInstance.get(getMiddlewareFile("get-countries"), {
      headers: authHeader("get-countries"),
    })
    .then((response) => {
      return response.data;
    });
  }
}

export default new AuthService();
