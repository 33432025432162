import axiosInstance from "@/plugins/HTTP";
import authHeader from "./auth-header";
import { com_EncryptString } from "@/utils/globalFunctions";
import {postMiddlewareFile,getMiddlewareFile,updateMiddlewareFile,deleteMiddlewareFile } from "@/utils/apiMiddlewarePaths";
const api_endpoint_prefix = "layer_client";

//Pass Same Param for authHeader and MiddlewareFile Method

class ClientService {

  getMember(params) {
    let queryString = "";
    if (params.searchdata) {
      queryString = "?searchdata=" + params.searchdata;
    }
    return axiosInstance.get(getMiddlewareFile("members", api_endpoint_prefix) + queryString, {
      headers: authHeader("members", api_endpoint_prefix),
    });
  }

  storeMember(MemberData) {
    const form_data = new FormData();
    for (var key in MemberData) {
      form_data.append(key, MemberData[key]);
    }
    return axiosInstance
    .post(postMiddlewareFile("members", api_endpoint_prefix), form_data, {
      headers: authHeader("members", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  storeWhitelistIps(WhitelistipsData) {
    const form = new FormData();
    form.append("whitelistIps", WhitelistipsData.whitelistIps);
    form.append("member", JSON.stringify(WhitelistipsData.member));
    return axiosInstance
    .post(postMiddlewareFile("whitelistip", api_endpoint_prefix), form, {
      headers: authHeader("whitelistip", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  getWhiteListIps(params) {
    let queryString = "";
    if (params.searchData) {
      queryString = "?searchdata=" + params.searchData;
    }
    return axiosInstance.get(getMiddlewareFile("whitelistip", api_endpoint_prefix) + queryString, {
      headers: authHeader("whitelistip", api_endpoint_prefix),
    });
  }

  updateWhitelistIps(whitelistipData){
    const form = new FormData();
    form.append("id", whitelistipData.edit_id);
    form.append("username", whitelistipData.edit_username);
    form.append("whitelistIps", whitelistipData.edit_whitelistIPs);
    return axiosInstance
      .post(updateMiddlewareFile("whitelistip", api_endpoint_prefix), form, {
        headers: authHeader("whitelistip", api_endpoint_prefix),
      })
      .then((response) => {
        return response.data;
      });
  }

  deleteWhitelistIp(whitelistipData) {
    const form = new FormData();
    form.append("id", whitelistipData.edit_id);
    return axiosInstance
      .post(deleteMiddlewareFile("whitelistip", api_endpoint_prefix), form, {
        headers: authHeader("whitelistip", api_endpoint_prefix),
      })
      .then((response) => {
        return response.data;
      });
  }

  updateMember(MemberData) {
    const form = new FormData();
    form.append("id", MemberData.edit_id);
    form.append("username", MemberData.edit_username);
    form.append("contact_email", MemberData.edit_contact_email);
    form.append("password", MemberData.edit_password);
    form.append("status", MemberData.edit_status);
    return axiosInstance
      .post(updateMiddlewareFile("members", api_endpoint_prefix), form, {
        headers: authHeader("members", api_endpoint_prefix),
      })
      .then((response) => {
        return response.data;
      });
  }

  getGateway(params) {
    let queryString = "";
    if (params.searchData) {
      queryString = "?searchdata=" + params.searchData;
    }
    return axiosInstance.get(getMiddlewareFile("gateways", api_endpoint_prefix) + queryString, {
      headers: authHeader("gateways", api_endpoint_prefix),
    });
  }

  storeGateway(GatewayData) {
    const form_data = new FormData();
    for (var key in GatewayData) {
      form_data.append(key, GatewayData[key]);
    }
    return axiosInstance
    .post(postMiddlewareFile("gateways", api_endpoint_prefix), form_data, {
      headers: authHeader("gateways", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  updateGateway(GatewayData) {
    const form = new FormData();
    form.append("id", GatewayData.edit_id);
    form.append("name", GatewayData.edit_name);
    form.append("description", GatewayData.edit_description);
    form.append("status", GatewayData.edit_status);
    return axiosInstance
      .post(updateMiddlewareFile("gateways", api_endpoint_prefix), form, {
        headers: authHeader("gateways", api_endpoint_prefix),
      })
      .then((response) => {
        return response.data;
      });
  }

  deleteGateway(GatewayData) {
    const form = new FormData();
    form.append("id", GatewayData.edit_id);
    return axiosInstance
      .post(deleteMiddlewareFile("gateways", api_endpoint_prefix), form, {
        headers: authHeader("gateways", api_endpoint_prefix),
      })
      .then((response) => {
        return response.data;
      });
  }

  getCountry() {
    return axiosInstance.get(getMiddlewareFile("get-countries", api_endpoint_prefix) , {
      headers: authHeader("get-countries", api_endpoint_prefix),
    });
  }

  getServer(params) {
    let queryString = "";
    if (params.searchdata) {
      queryString = "?searchdata=" + params.searchdata;
    }
    return axiosInstance.get(getMiddlewareFile("server", api_endpoint_prefix) + queryString, {
      headers: authHeader("server", api_endpoint_prefix),
    });
  }

  storeTeam(TeamData) {
    const form = new FormData();
    form.append("team_name", TeamData.team_name);
    form.append("status", TeamData.status);
    form.append("members", JSON.stringify(TeamData.members));
    form.append("gateways", JSON.stringify(TeamData.gateways));
    return axiosInstance
    .post(postMiddlewareFile("teams", api_endpoint_prefix), form, {
      headers: authHeader("teams", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  updateTeamData(TeamData){
    const form = new FormData();
    form.append("id", TeamData.edit_id);
    form.append("team_name", TeamData.edit_name);
    form.append("status", TeamData.edit_status);
    form.append("members", JSON.stringify(TeamData.members));
    form.append("gateways", JSON.stringify(TeamData.gateways));
    return axiosInstance
    .post(updateMiddlewareFile("teams", api_endpoint_prefix), form, {
      headers: authHeader("teams", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  getTeam(params) {
    let queryString = "";
    if (params.searchdata) {
      queryString = "?searchdata=" + params.searchdata;
    }
    return axiosInstance.get(getMiddlewareFile("teams", api_endpoint_prefix) + queryString, {
      headers: authHeader("teams", api_endpoint_prefix),
    });
  }

  getTeamWiseDetails(param) {
    let queryString = "";
    if (param.teamId) {
      queryString = "?teamId=" + param.teamId;
    }
    return axiosInstance.get(getMiddlewareFile("get-teamwisedata", api_endpoint_prefix) + queryString, {
      headers: authHeader("get-teamwisedata", api_endpoint_prefix),
    });
  }

  getGatewayWiseDetails(param) {
    let queryString = "";
    if (param.GatewayId) {
      queryString = "?GatewayId=" + param.GatewayId;
    }
    return axiosInstance.get(getMiddlewareFile("get-gatewaywisedata", api_endpoint_prefix) + queryString, {
      headers: authHeader("get-gatewaywisedata", api_endpoint_prefix),
    });
  }
//not done
  addGatewayServers(serverData) {
    const form = new FormData();
    form.append("priority", serverData.priority);
    form.append("Gateway_id", serverData.Gateway_id);
    form.append("servers", JSON.stringify(serverData.serverList));
    return axiosInstance
    .post(postMiddlewareFile("add-server-to-gateway", api_endpoint_prefix), form, {
      headers: authHeader("add-server-to-gateway", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  RemoveServer(RemoveData) {
    const form = new FormData();
    form.append("id", RemoveData.remove_server_id);
    form.append("gateway_id", RemoveData.remove_gateway_id);
    return axiosInstance
    .post(postMiddlewareFile("remove-server-from-gateway", api_endpoint_prefix), form, {
      headers: authHeader("remove-server-from-gateway", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  getPackage() {
    return axiosInstance.get(getMiddlewareFile("packages", api_endpoint_prefix) , {
      headers: authHeader("packages", api_endpoint_prefix),
    });
  }

  getCoupon(param) {
    let queryString = "";
    if (param.coupon_code) {
      queryString = "?coupon_code=" + param.coupon_code;
    }
    return axiosInstance
      .get(getMiddlewareFile("get-campaign-coupon", api_endpoint_prefix) + queryString, {
        headers: authHeader("get-campaign-coupon", api_endpoint_prefix),
      })
      .then((response) => {
        return Promise.resolve(response.data.data);
      });
  }

  getAppSettingsData(param){
    let queryString = "";
    queryString = "?app-settings=" + param.userId;    
    return axiosInstance.get(getMiddlewareFile("get-app-settings", api_endpoint_prefix) + queryString, {
      headers: authHeader("get-app-settings", api_endpoint_prefix),
    });
  }

  updateAppSettingsData(settingsData){
    const form = new FormData();
    form.append("company_id", settingsData.company_id);
    if (settingsData.TwoFASwtich !== '') {
      form.append("two_factor_authentication", settingsData.TwoFASwtich);
      form.append("app_login_methods", settingsData.appLogin);
      form.append("single_sign_on", settingsData.singleSignOn);
    }
    if (settingsData.appUpdate !== '') {
      form.append("auto_app_update", settingsData.appUpdate);
      form.append("threat_block", settingsData.threatBlock);
    }
    return axiosInstance
    .post(postMiddlewareFile("update-app-settings", api_endpoint_prefix), form, {
      headers: authHeader("update-app-settings", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  updateCompanyData(companyData){
    const form = new FormData();
    for (var key in companyData) {
      form.append(key, companyData[key]);
    }
    return axiosInstance
    .post(postMiddlewareFile("update-company", api_endpoint_prefix), form, {
      headers: authHeader("update-company", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  updatePasswordData(passwordData){
    const form = new FormData();
    for (var key in passwordData) {
      form.append(key, passwordData[key]);
    }
    return axiosInstance
    .post(postMiddlewareFile("update-password", api_endpoint_prefix), form, {
      headers: authHeader("update-password", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  getCompanyInfo(param){
      let queryString = "";
      queryString = "?searchdata=" + param.searchdata;
      return axiosInstance.get(getMiddlewareFile("company", api_endpoint_prefix) + queryString, {
        headers: authHeader("company", api_endpoint_prefix),
      });
  }

  CancelSubscription(obj){
    const form_data = new FormData();
    for (var key in obj) {
      form_data.append(key, obj[key]);
    }
    return axiosInstance
    .post(postMiddlewareFile("update-app-settings", api_endpoint_prefix), form_data, {
      headers: authHeader("update-app-settings", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  UpdateAutoRenewal(obj){
    const form = new FormData();
    form.append("company_id", obj.company_id);
    form.append("auto_renew_status", obj.auto_renewal);
    return axiosInstance
    .post(postMiddlewareFile("update-renew-status", api_endpoint_prefix), form, {
      headers: authHeader("update-renew-status", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  getNotification() {
    return axiosInstance.get(getMiddlewareFile("notification", api_endpoint_prefix) , {
      headers: authHeader("notification", api_endpoint_prefix),
    });
  }

  updateNotifyStatus(notifyData) {
    const form = new FormData();
    form.append("is_read", 1);
    form.append("id", notifyData.id);

    return axiosInstance
      .post(updateMiddlewareFile("notification", api_endpoint_prefix), form, {
        headers: authHeader("notification", api_endpoint_prefix),
      })
      .then((response) => {
        return response.data;
      });
  }

  sslPay(order) {
    const form = new FormData();
    form.append("data_token", com_EncryptString(JSON.stringify(order)));
    form.append("id", order.company_id);
    form.append("username", order.username);
    form.append("plan_details_id", order.plan_details_id);
    form.append("amount", order.amount);
    form.append("base_payable_amount", order.base_amount);
    form.append("perUserPrice", order.perUserPrice);
    form.append("user_limit", order.user_limit);
    form.append("ip", order.ip);
    form.append("coupon_code", order.coupon_code);
    form.append("ip", order.ip);
    return axiosInstance
    .post(postMiddlewareFile("ssl-pay", api_endpoint_prefix), form, {
      headers: authHeader("ssl-pay", api_endpoint_prefix),
    })
    .then((response) => {
      return response.data;
    });
  }

  getPaymentHistory(param){
    let queryString = "";
    queryString = "?id=" + param.userId;
    return axiosInstance.get(getMiddlewareFile("get-payment-history", api_endpoint_prefix) + queryString, {
      headers: authHeader("get-payment-history", api_endpoint_prefix),
    });
  }

  getActivity(params){
    let queryString = "";
    if (params.searchdata) {
      queryString = "?searchdata=" + params.searchdata;
    }
    return axiosInstance.get(getMiddlewareFile("get-activity-log", api_endpoint_prefix) + queryString, {
      headers: authHeader("get-activity-log", api_endpoint_prefix),
    });
  }
  getApiRefreshToken() {
    return axiosInstance.get(getMiddlewareFile("refresh-token", api_endpoint_prefix), {
      headers: authHeader("refresh-token", api_endpoint_prefix),
    });
  }

  verifyJwtToken() {
    return axiosInstance.get(getMiddlewareFile("verify-token", api_endpoint_prefix), {
      headers: authHeader("verify-token", api_endpoint_prefix),
    });
  }
}


export default new ClientService();
